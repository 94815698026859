<template>
	<div>
		<van-nav-bar
			title="栏目列表"
			left-text="返回"
			left-arrow
			@click-left="onClickLeft"
		/>
		
		<van-tabs v-model="activeIndex" @change="changeEvent" animated swipeable sticky>
			<van-tab v-for="(item,index) in tabBars" :key="index" :title="item.name">
				<van-pull-refresh v-model="pages[index].isLoading" @refresh="onRefresh">
					<div v-if="pages[index].data" style="min-height: calc(100vh - 250px);">
						
                        <div v-for="(item1,index1) in listData[index].list" :key="index1">
                            <img-text-list-max :item="item1" :index="index1" :lanmu="item1.cateogry.name" :slug="item1.cateogry.slug"></img-text-list-max>
                        </div>
                            
                        <div v-if="pages[index].pageTotal > 1" class="m-t-30" style="padding: 0 20px;">
                            <van-pagination v-model="pages[index].page" :page-count="pages[index].pageTotal" :total-items="pages[index].pageNum"  @change="pageEvent">
                                <template #prev-text>
                                    <van-icon name="arrow-left" />
                                </template>
                                <template #next-text>
                                    <van-icon name="arrow" />
                                </template>
                                <template #page="{ text }">{{ text }}</template>
                            </van-pagination>
                        </div>
					</div>
					<div v-else style="min-height: calc(100vh - 250px);">
						<van-empty :description="$common.noDataText" />
					</div>
                    <common-footer></common-footer>
				</van-pull-refresh>
			</van-tab>
		</van-tabs>
		
	</div>
</template>

<script>
	import imgTextListMax from '../components/list/img-text-list-max.vue'
	
	export default {
		components:{
			imgTextListMax
		},
		data() {
			return {
				activeIndex:0,
				tabBars:[],
				listData:[],
				pages:[]
			}
		},
		mounted() {
			this.getTabBars()
		},
		methods:{
            
			getTabBars(){
				this.$api.article.getCategoriesList().then((res) => {
                    let tabBars = [
                        {
                            id:'',
                            name:'全部',
                            slug:'',
                            sort:''
                        }
                    ]
                    for(let i=0;i<res.data.length;i++){
                        tabBars.push(res.data[i])
                    }
                    this.tabBars = tabBars
					this.tabBarsDataList()
					if(res.data.length > 0) this.getList()
				})
			},
			getList(event=''){
                let page = this.pages[this.activeIndex].page
                if((page == 1 && this.listData[this.activeIndex].list.length == 0) || event != ''){
                    if(this.activeIndex == 0){
                        this.$api.article.getArticlesAll(page).then((res) => {
                            this.listDataVal(res, event)
                        })
                    }else{
                        this.$api.article.getCategoriesArticleList(this.tabBars[this.activeIndex].slug, page).then((res) => {
                            this.listDataVal(res, event)
                        })
                    }
                }
			},
            listDataVal(res, event){
                if(event == 'page'){
                    // 回到顶部
                    document.body.scrollTop = 0;
                    document.documentElement.scrollTop = 0;
                }
                this.listData[this.activeIndex].list = res.data
                let pageTotal = this.$computed.page(res.meta.total, res.meta.per_page)
                this.pages[this.activeIndex].pageTotal = pageTotal
                this.pages[this.activeIndex].pageNum = res.meta.total
                
                if(res.data == 0){
                    this.pages[this.activeIndex].data = false
                }
            },
			onRefresh(){  // 上拉刷新
				setTimeout(() => {
					this.pages[this.activeIndex].page = 1
                    this.pages[this.activeIndex].isLoading = false;
					this.getList('refresh')
					console.log('刷新成功')
				}, 1000);
			},
            pageEvent(page){
                this.pages[this.activeIndex].page = page
                this.getList('page')
            },
			onLoad() { // 上拉加载更多
				setTimeout(() => {
					this.getList()
				}, 1500);
			},
			tabBarsDataList(){
				let arr = []
				let pages = []
				for(var i=0; i<this.tabBars.length; i++){
					let list = {list:[]}
					arr.push(list)
					let page = {page:1,isLoading:false,pageTotal:'',pageNum:'',data:true}
					pages.push(page)
				}
				this.listData = arr
				this.pages = pages
			},
			changeEvent(index){
				this.activeIndex = index
				this.getList()
			},
			onClickLeft() {
				this.$router.go(-1);
			}
		}
	}
</script>

<style>
</style>
